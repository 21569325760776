

// export const socialMediaLinks = [
//     { name: 'Facebook', url: "https://www.facebook.com/ludhiananow", imageUrl: FacebookImage },
//     { name: 'Twitter', url: "https://twitter.com/LudhianaNow", imageUrl: TwitterImage },
//     { name: 'Instagram', url: "https://www.instagram.com/ludhiana_now/", imageUrl: InstagramImage },
//     { name: 'YouTube', url: "https://www.youtube.com/@ludhiana_now", imageUrl: YouTubeImage }
// ];
export const footerDescription = {
    content: `Certainly! Here's a Lorem Ipsum paragraph for you: <br />
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vel libero ut elit pretium malesuada. Integer at ex id metus convallis facilisis. <br /> Sed luctus risus eu diam ultricies, eget facilisis enim bibendum. Suspendisse potenti. Duis vel vehicula metus.`,
};

export const footerContent = [
    {
        column1: [
            { text: 'copyright 4-12-2023', className: 'list-unstyled' },
            { text: 'Privacy policy', className: 'list-unstyled' },
            { text: 'Terms And Conditions', className: 'list-unstyled' }
        ]
    },
    {
        column2: [
            { iconClass: 'fa-regular fa-envelope', text: '+91 655659865', className: 'list-unstyled' },
            { iconClass: 'fa-solid fa-phone', text: '+91 655659865', className: 'list-unstyled' },
            { iconClass: 'fa-solid fa-location-dot', text: 'Raho Raod Rd Meharban Rd, Ludhiana', className: 'list-unstyled' }
        ]
    }
];

