import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Axios from 'axios';
import Header from './Components/Header';
import Footer from './Components/Footer';
import Home from './Contents/Home';
import Blog from './Contents/Blog';
import Punjab from './Contents/Punjab';
import Videos from './Contents/Videos';
import Sports from './Contents/Sports';
import Trending from './Contents/Trending';
import Artical from './Contents/Artical'
import Searchcontent from './Components/Searchcontent';
function App() {
  const [data, setData] = useState({});
  const apiUrl = 'https://ludhiananow.com/wp-json/wp/v2/posts';

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Axios.get(apiUrl);
        if (response && response.data) {
          setData(response.data);
        } else {
          console.error(`Failed to fetch data. Status code: ${response.status}`);
        }
      }
      catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);


  return (
    <Router>
      <Header data={data} />
      <Routes>
        <Route path="/" element={<Home data={data} />} />
        <Route path="/blog" element={<Blog data={data} />} />
        <Route path="/punjab" element={<Punjab data={data} />} />
        <Route path="/videos" element={<Videos data={data} />} />
        <Route path="/sports" element={<Sports data={data} />} />
        <Route path="/trending" element={<Trending />} />
        <Route path=":slug" element={<Artical data={data} />} />
        <Route path="/search/" element={<Searchcontent data={data} />} />
      </Routes>
      <Footer data={data} />
    </Router>
  );
}

export default App;
