import React from 'react'
import Visit from './Visit';
export default function Punjabcontent(data) {
    const postUrl = 'https://ludhiananow.com/wp-json/wp/v2/posts';

    return (
        <>
            <div className="container">
                <div className="footer-icons  my-4">
                    <div className="all-footer-icons pt-3 d-flex">
                        <Visit url={postUrl} />
                    </div>
                </div>
                <div className="blog-heading text-center d-flex align-items-center justify-content-center  digger-font-family  ">
                    <h1 className='fw-bold '>PUNJAB NEWS</h1>
                </div>

            </div>
        </>
    )
}
