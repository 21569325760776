import React from 'react'
import { Link } from 'react-router-dom'
import Popular from './Popular'
import Latest from './Latest'
import Popular2 from './Popular2'
export default function Homesection2(data) {
  return (
    <>
      <div className="container-fluid ">
        <div className="container">
          <div className="row">
            <div className="col-md-9 home-page-contants">
              {data.data.data.map((post) => (
                <div key={post.id}>
                  <div className="second-home-header my-5">
                    <Link to={`/${post.slug}`}>
                      <h1 className='digger-font-family'>{post.title.rendered}</h1>
                    </Link>
                  </div>
                  <div className="secondbg-image my-4">
                    <Link to={`/${post.slug}`}>
                      <img className='w-100 latest-img' src={post.thumbnail_image_url} alt="" />
                    </Link>
                  </div>
                  <div className="row home-admin">
                    <div className="col-md-6 col-sm-6 col-6">
                      <div className="time-date digger-font-family d-flex gap-4">
                        <div className="time flex-wrap d-flex align-items-center gap-2">
                          <i className="fa-regular fa-clock"></i>
                          <p className="m-0">{new Intl.DateTimeFormat('en-US', {
                            hour: 'numeric',
                            minute: 'numeric',
                            second: 'numeric',
                          }).format(new Date(post.date))} </p>
                        </div>
                        <div className="date flex-wrap d-flex align-items-center gap-2">
                          <i className="fa-regular fa-calendar"></i>
                          <p className="m-0">  {new Intl.DateTimeFormat('en-US', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                          }).format(new Date(post.date))}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-6">
                      <div className="time-date digger-font-family d-flex justify-content-end gap-4">
                        <div className="time d-flex align-items-center gap-2">
                          <i className="fa-regular fa-user"></i>
                          <p className="m-0">Ludhiana News</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="col-md-3 py-4 popular-subscribe">
              <Latest />
              <Popular />
              <Popular2 />
            </div>
          </div>
        </div>
      </div>

    </>
  )
}
