import React from 'react'
import Sportscontant from '../Components/Sportscontant'
import Contactform from '../Components/Contactform'


export default function Sports(data) {
  if (data.data.length > 0) {
    return (
      <>
        <Sportscontant data={data} />
        <Contactform/>
     
      </>
    )
  }
  else {

  }
}
