

import React from 'react';
import insta from '../News-_images/Instagram.png'
import fb from '../News-_images/Facebook.png'
import tw from '../News-_images/Twitter.png'
import youtube from '../News-_images/Youtube.png'
const Visit = ({ url }) => {
    const shareOnFacebook = () => {
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}`, '_blank');
    };

    const shareOnTwitter = () => {
        window.open(`https://twitter.com/intent/tweet?url=${url}`, '_blank');
    };

    const shareOnInstagram = () => {
        window.open(`https://www.instagram.com/sharer.php?u=${url}`, '_blank');
    };

    const shareOnYouTube = () => {
        window.open(`https://www.youtube.com/watch?v=${url}`, '_blank');
    };

    return (
        <div className="share-icons">
            <img src={fb} onClick={shareOnFacebook} alt="facebookicon" />
            <img src={tw} onClick={shareOnTwitter} alt="twittericon" />
            <img src={youtube} onClick={shareOnYouTube} alt="youtubeicon" />
            <img src={insta} onClick={shareOnInstagram} alt="instagramicon" />
        </div>
    );
};

export default Visit;





