import React, { useState } from 'react';
export default function Contactform() {
    const [formData, setFormData] = useState({
        name: '',
        surname: '',
        email: '',
        subject: '',
        message: ''
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors = validateForm(formData);
        if (Object.keys(validationErrors).length === 0) {
            // Form is valid, perform form submission logic here
            console.log('Form submitted:', formData);
            // Reset form fields
            setFormData({
                name: '',
                surname: '',
                email: '',
                subject: '',
                message: ''
            });
        } else {
            // Set errors if there are validation errors
            setErrors(validationErrors);
        }
    };

    const validateForm = (data) => {
        let errors = {};
        if (!data.name.trim()) {
            errors.name = 'Name is required';
        }
        if (!data.surname.trim()) {
            errors.surname = 'Surname is required';
        }
        if (!data.email.trim()) {
            errors.email = 'Email is required';
        } else if (!isValidEmail(data.email)) {
            errors.email = 'Invalid email address';
        }
        // You can add more validation rules for other fields here
        return errors;
    };

    const isValidEmail = (email) => {
        // Basic email validation regex
        return /\S+@\S+\.\S+/.test(email);
    };

    return (
        <>
            <div className="container my-5">
                <div className="row justify-content-center">
                    <div className="col-lg-9">
                        <h1 className="mb-3">Contact Us</h1>
                        <form onSubmit={handleSubmit}>
                            <div className="row g-3">
                                <div className="col-md-6">
                                    <label htmlFor="your-name" className="form-label">Your Name</label>
                                    <input type="text" className="form-control" id="your-name" name="name" value={formData.name} onChange={handleChange} required />
                                    {errors.name && <div className="text-danger">{errors.name}</div>}
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="your-surname" className="form-label">Your Surname</label>
                                    <input type="text" className="form-control" id="your-surname" name="surname" value={formData.surname} onChange={handleChange} required />
                                    {errors.surname && <div className="text-danger">{errors.surname}</div>}
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="your-email" className="form-label">Your Email</label>
                                    <input type="email" className="form-control" id="your-email" name="email" value={formData.email} onChange={handleChange} required />
                                    {errors.email && <div className="text-danger">{errors.email}</div>}
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="your-subject" className="form-label">Your Subject</label>
                                    <input type="text" className="form-control" id="your-subject" name="subject" value={formData.subject} onChange={handleChange} />
                                </div>
                                <div className="col-12">
                                    <label htmlFor="your-message" className="form-label">Your Message</label>
                                    <textarea className="form-control" id="your-message" name="message" value={formData.message} onChange={handleChange} rows="5" required></textarea>
                                </div>
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <button type="submit" className="btn btn-dark w-100 fw-bold">Send</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}
