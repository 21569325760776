// import React, { useEffect, useState } from 'react';
// import Axios from 'axios';
// import { Link } from 'react-router-dom';

// export default function Popular() {
//   const [data, setData] = useState([]);

//   const apiUrl = 'https://ludhiananow.com/wp-json/wp/v2/posts?categories=18';

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await Axios.get(apiUrl);
//         if (response && response.data) {
//           setData(response.data);
//         } else {
//           console.error(`Failed to fetch data. Status code: ${response.status}`);
//         }
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }
//     };

//     fetchData();
//   }, []);


//   const [user, setUser] = useState({ email: "" });
//   const getuseremail = (event) => {
//     const { name, value } = event.target;
//     setUser({ ...user, [name]: value });
//   };

//   const postdata = async (event) => {
//     event.preventDefault();

//     const errorMessageDiv = document.getElementById("errorMessages");
//     if (!validateEmail(user.email)) {
//       errorMessageDiv.innerHTML = "Please enter a valid email address.";
//       return;
//     }

//     try {
//       const res = await fetch("https://news-app-bee6b-default-rtdb.firebaseio.com/Newslogin.json", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({
//           email: user.email,
//         })
//       });

//       if (res.ok) {
//         setUser({ email: "" });
//         errorMessageDiv.innerHTML = "Your data was  successfully!";
//       } else {
//         errorMessageDiv.innerHTML = "Failed to store data. Please try again!";

//       }
//     } catch (error) {
//       console.error('Error posting data:', error);
//       errorMessageDiv.innerHTML = "Failed to store data. Please try again!";

//     }
//   };

//   const validateEmail = (email) => {
//     const re = /\S+@\S+\.\S+/;
//     return re.test(email);
//   };

//   return (
//     <>

//       <div className="second-home-input">
//         <div className="input-group  mb-3 position-relative SUBSCRIBE-input">
//           <i className="fa-regular fa-envelope envelope"></i>
//           <form className='d-flex' method='POST'>
//             <input
//               type="email"
//               className="form-control ps-5 py-2 custom-input"
//               placeholder="SUBSCRIBE"
//               aria-label="Recipient's username"
//               aria-describedby="basic-addon2"
//               required
//               name="email"
//               value={user.email}
//               onChange={getuseremail}
//             />
//             <button type="submit" className="input-group-text btn" id="basic-addon2" onClick={postdata}>
//               <i className="fa-regular fa-paper-plane"></i>
//             </button>
//           </form>
//           <div className="errorMessages text-danger" id='errorMessages'></div>
//         </div>
//       </div>

//       <div className="latest-part">
//         <h4 className='digger-news-color digger-font-family'>LATEST</h4>
//       </div>

//       {data.map((post) => (
//         <div className="latest-img col-md-12" key={post.id}>
//           <div className="row mb-3">
//             <div className="col-md-4 home-popular col-sm-6">
//               <Link to={`/${post.slug}`}>
//                 <img className='w-100 latest-img' src={post.thumbnail_image_url} alt="" />
//               </Link>
//             </div>
//             <div className="latest-discripition col-md-8 col-sm-6">
//               <Link to={`/${post.slug}`}>
//                 <h6> {post.title.rendered}</h6>
//               </Link>
//               <div className="time d-flex align-items-center gap-2 flex-wrap">
//                 <p className="m-0">Ludhiana News</p>
//                 <p className="m-0">{new Intl.DateTimeFormat('en-US', {
//                   year: 'numeric',
//                   month: 'long',
//                   day: 'numeric',
//                 }).format(new Date(post.date))}</p>
//               </div>
//             </div>
//           </div>
//         </div>
//       ))}
//     </>
//   );
// }


import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom';

export default function Popular() {
  const [data, setData] = useState([]);
  const apiUrl = 'https://ludhiananow.com/wp-json/wp/v2/posts?categories=3 ';

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Axios.get(apiUrl);
        if (response && response.data) {
          setData(response.data);
        } else {
          console.error(`Failed to fetch data. Status code: ${response.status}`);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const mydata = data.slice(0, 5)
  return (

    <>
      <div className="latest-part">
        <h4 className='digger-news-color digger-font-family'>POPULAR</h4>
      </div>

      {mydata.map((featured_media) => (
        <div className="latest-img col-md-12" key={featured_media.id}>
          <div className="row mb-3">
            <div className="col-md-4 home-popular col-sm-6">
              <Link to={`/${featured_media.slug}`}>
                <img className='w-100 latest-img' src={featured_media.thumbnail_image_url} alt="Image" />
              </Link>
            </div>
            <div className="latest-discripition col-md-8 col-sm-6">
              <Link to={`/${featured_media.slug}`}>
                <h6> {featured_media.title.rendered}</h6>
              </Link>
              <div className="time d-flex align-items-center gap-2 flex-wrap">
                <p className="m-0">Ludhiana News</p>
                <p className="m-0">{new Intl.DateTimeFormat('en-US', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                }).format(new Date(featured_media.date))}</p>
              </div>
            </div>
          </div>
        </div>

      ))}
    </>
  );
}