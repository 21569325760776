import React, { useState, useEffect } from 'react';
import axios from 'axios';
import logo from '../News-_images/footerlogo.png';
import { Link } from 'react-router-dom';
import { socialMediaLinks, footerDescription, footerContent } from "../Contents/Staticcon";
export default function Footer(data) {
  const [menuItems, setMenuItems] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://ludhiananow.com/wp-json/wp/v2/menu-items', {
          auth: {
            username: 'logicdigger',
            password: 'YcJe pQHx nrXc AQEB Gf0h H83X',
          },
        });
        setMenuItems(response.data);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchData();
  }, []);
  const routes = [
    { path: '/', label: 'Home' },
    { path: '/blog', label: 'Blog' },
    { path: '/punjab', label: 'Punjab' },
    { path: '/trending', label: 'Trending' },
    { path: '/videos', label: 'Videos' },
    { path: '/sports', label: 'Sports' },
  ];


  return (
    <>
      <div className="container-fulid footer-section">
        <div className="container text-center">
          <div className="col-md-10 m-auto py-4">
            <Link className="footer-image" to="/">
              <img src={logo} alt="logo" />
            </Link>
            <div className="footer-icons">
              <div className="all-footer-icons pt-3 d-flex justify-content-center">
                {/* {socialMediaLinks.map((socialMedia, index) => (
                  <div key={index} className={socialMedia.name}>
                    <Link to={socialMedia.url} target="_blank" rel="noopener noreferrer">
                      <img src={socialMedia.imageUrl} alt={socialMedia.name} />
                    </Link>
                  </div>
                ))} */}
              </div>
            </div>
            <div className="footer-discripition text-light digger-font-family">
              <p dangerouslySetInnerHTML={{ __html: footerDescription.content }} />
            </div>
            <div className="footer-nav-bar">
              <nav className="navbar navbar-expand-lg navbar-light footer-main-nav">
                <div className="container-fluid text-center d-flex align-items-center justify-content-center">
                  <div className="navbar d-block" id="navbarNav">
                    <ul className="navbar-nav digger-font-family digger-font-size me-auto mb-2 mb-lg-0 fw-semibold main-header-links header-navbar-con colum text-center">
                      {menuItems.map((menuItem, index) => (
                        <li key={index} className="nav-item digger-font-weight col">
                          <Link className="text-light nav-link py-0 px-3 all-navbar digger-news-color news-nav-pages" aria-current="page" to={routes[index].path}>
                            {menuItem.title.rendered}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fulid digger-bg-color">
        <div className="container">
          <div className="row">
            {footerContent.map((content, index) => (
              <div key={index} className="col-md-6">
                <div className="flex-wrap d-flex justify-content-between latest-news m-0 py-2 ps-0 text-light footer-font-family">
                  {Object.values(content)[0].map((item, idx) => (
                    <div key={idx} className={item.className}>
                      {item.iconClass && <i className={item.iconClass}></i>}
                      <span>{item.text}</span>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>

        </div>
      </div>
    </>
  );
}
