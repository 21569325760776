
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Popular from './Popular';
import Popular2 from './Popular2';
import Latest from './Latest';

// Function to strip HTML tags from a string
const stripHtmlTags = (html) => {
    const strippedText = html.replace(/<[^>]*>?/gm, ''); // Remove HTML tags using regex
    return strippedText;
};

export default function ArticleSection({ data }) {
    const { slug } = useParams();
    const [article, setArticle] = useState(null);

    useEffect(() => {
        if (data.data.length > 0) {
            const articlesArray = data.data;
            const foundArticle = articlesArray.find((item) => {
                const decodedSlug = decodeURIComponent(slug);
                const decodedItemSlug = decodeURIComponent(item.slug);
                return decodedSlug === decodedItemSlug;
            });
            setArticle(foundArticle);
        }
    }, [data, slug]);

    useEffect(() => {
        if (article) {
            document.title = `${article.title.rendered}`;
        }
    }, [article]);

    return (
        <>

            {article && (
                <Helmet>
                    <title>{article.title.rendered}</title>
                    <meta name="description" content={stripHtmlTags(article.excerpt.rendered)} />
                    <meta name="author" content={article.author} />
                    <meta property="og:title" content={article.title.rendered} />
                    <meta property="og:description" content={stripHtmlTags(article.excerpt.rendered)} />
                    <meta property="og:image" content={article.featured_image_url} />
                </Helmet>
            )}

            <div className="container-fluid">
                <div className="container">
                    <div className="row">
                        <div className="col-md-9 blog-page-content">
                            {article ? (
                                <div className='mt-4'>
                                    <img src={article.featured_image_url} className="card-img-top" alt="..." />
                                    <h2>{article.title.rendered}</h2>
                                    <div dangerouslySetInnerHTML={{ __html: article.content.rendered }} />
                                </div>
                            ) : (
                                <h1>Article not found</h1>
                            )}
                        </div>
                        <div className="col-md-3 py-4 popular-subscribe">
                            <Latest />
                            <Popular />
                            <Popular2 />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
