import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import '../App.css';
import Visit from './Visit';


export default function Homesection() {
    const postUrl = 'https://ludhiananow.com/wp-json/wp/v2/posts';
    const [data, setData] = useState([]);
    const apiUrl = 'https://ludhiananow.com/wp-json/custom/v1/basic?';
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await Axios.get(apiUrl);
                if (response && response.data) {
                    setData(response.data);
                } else {
                    console.error(`Failed to fetch data. Status code: ${response.status}`);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);
    // console.log(data);
    return (
        <div className="slider-container">
            <div id="carouselExampleCaptions" className="carousel slide h-100" data-bs-ride="carousel">
                <div className="carousel-inner h-100">
                    {data.map((post, index) => (
                        <div className={`carousel-item h-100 ${index === 0 ? "active" : ""}`} key={index}>
                            <Link to={post.slug ? `/${post.slug}` : ''}>
                                <img src={post} className="d-block w-100 carousel-img h-100" alt={`Slide ${index + 1}`} />
                            </Link>
                            <div className="carousel-caption d-none d-md-block text-start">
                                <div className="home-page-contents">
                                    <div className="news-btn digger-font-family">
                                        <button type="button" className="text-light digger-bg-color">NEWS</button>
                                    </div>
                                    <div className="home-heading">
                                        <h1 className="text-light fw-bolder digger-font-family">{post.slug}</h1>
                                    </div>
                                </div>
                                <div className="time-date digger-font-family d-flex gap-4">
                                    <div className="time d-flex text-light align-items-center gap-2">
                                        <p className="m-0">{post.date ? new Intl.DateTimeFormat('en-US', {
                                            hour: 'numeric',
                                            minute: 'numeric',
                                            second: 'numeric',
                                        }).format(new Date(post.date)) : 'Invalid Date'}</p>
                                    </div>
                                    <div className="date d-flex text-light align-items-center gap-2">
                                        <i className="fa-regular fa-calendar"></i>
                                        <p className="m-0">{post.date ? new Intl.DateTimeFormat('en-US', {
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric',
                                        }).format(new Date(post.date)) : 'Invalid Date'}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>

            <div className="container">
                <div className="footer-icons  my-4">
                    <div className="all-footer-icons pt-3 d-flex">
                        <Visit url={postUrl} />
                    </div>
                </div>
            </div>
        </div>
    );
}