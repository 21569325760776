import React from 'react'
import Punjabcontent from '../Components/Punjabcontent'
import Homesection2 from '../Components/Homesection2'
import Demo from '../Components/Demo'
// import Errorpage from '../Components/Errorpage'

export default function Punjab(data) {
  if (data.data.length > 0) {
    return (
      <>
        {/* <Errorpage/> */}
        <Punjabcontent data={data} />
        <Homesection2 data={data} />
        <Demo />
      </>
    )
  } else {
  }
}

