import React from 'react'
import Viralcontant from '../Components/Viralcontant'

export default function Videos(data) {
  // console.log(data.data.data)
  if (data.data.length > 0) {
  return (
   <>
   <Viralcontant data={data} />
   </>
  )
  }
  else{ 
    
  }
}
