import React from 'react'
import { Link } from 'react-router-dom'
import Latest from './Latest'
import Popular from './Popular'
import Popular2 from './Popular2'
export default function Blogsection(data) {
    return (
        <>
            <div className="container-fluid ">
                <div className="container">
                    <div className="blog-heading text-center my-5 digger-font-family d-flex align-items-center justify-content-center">
                        <h1 className='fw-bold '>BLOG POST</h1>
                    </div>
                    <div className="row">
                        <div className="col-md-9 blog-page-contant">
                            <div className="row"> 
                                {data.data.data.map((post) => (
                                    <div className="col-md-4" key={post.id}>
                                        <div className="card border-0">
                                            <Link to={`/${post.slug}`}>
                                                <img src={post.thumbnail_image_url} className="card-img-top" alt="..." />
                                            </Link>
                                            <div className="card-body p-0">
                                                <Link to={`/${post.slug}`}>
                                                    <h1 className='digger-font-family'>{post.title.rendered}</h1>
                                                </Link>
                                                <div dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }} />
                                                <Link to={`/${post.slug}`}><button type="button" className="btn p-0 mb-5">
                                                    Read More
                                                </button></Link>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="col-md-3  py-4 popular-subscribe">
                            <Latest />
                            <Popular />
                            <Popular2 />
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
