import React from 'react'
// import Homesection2 from './Homesection2'
import { Link } from 'react-router-dom'
import Popular from './Popular'
import Latest from './Latest'
import Popular2 from './Popular2'
import Visit from './Visit'
export default function Viralcontant(data) {
    const postUrl = 'https://ludhiananow.com/wp-json/wp/v2/posts';
    return (
        <>
            <div className="container">
                <div className="all-footer-icons pt-3 d-flex">
                    <Visit url={postUrl} />
                </div>
                <div className="blog-heading text-center d-flex align-items-center justify-content-center  digger-font-family  ">
                    <h1 className='fw-bold '>VIRAL VIDEOES</h1>
                </div>
                <div className="blog-heading d-flex   digger-font-family my-4 ">
                    <h3 className='fw-bold '>CATEGORY:<span className='digger-news-color'>POLOTICS</span></h3>
                </div>
            </div>
            <div className="container-fulid">
                <div className="container">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="row">
                                {data.data.data.map((categories) => (
                                    <div className="col-md-4" key={categories.id}>
                                        <div className="politics-img">
                                            <Link to={`/${categories.slug}`}>
                                                <img className='w-100' src={categories.thumbnail_image_url} alt="" />
                                                <p className=''>{categories.title.rendered}</p>
                                            </Link>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="col-md-3  py-4 popular-subscribe">
                            <Latest />
                            <Popular />
                            <Popular2 />
                        </div>
                    </div>
                </div>
            </div>

        </>

    )
}
