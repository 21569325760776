// import React, { useState, useEffect } from 'react';

// const Demo = () => {
//   const [postData, setPostData] = useState(null);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await fetch('http://192.168.1.10:1337/api/posts');
//         if (!response.ok) {
//           throw new Error('Failed to fetch data');
//         }
//         const responseData = await response.json();
//         setPostData(responseData);
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }
//     };

//     fetchData();
//   }, []);

//   return (
//     <div>
//       <h1>Posts</h1>
//       {postData && postData.data.map(post => (
//         <div key={post.id}>
//           <h2>{post.attributes.Title}</h2>
//           {post.attributes.content.map((paragraph, index) => (
//             <p key={index}>{paragraph.children[0].text}</p>
//           ))}
//           <p>Category: {post.attributes.category}</p>   
//           <p>Published At: {post.attributes.publishedAt}</p>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default Demo;






import React, { useState, useEffect } from 'react';
function App() {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    fetch('http://192.168.1.10:1337/api/posts?populate=*')
      .then(response => response.json())
      .then(data => setPosts(data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  return (
    <div>

      <ul>
        {posts.map(post => (
          <li key={post.id}>
            <h2>{post.title}</h2>
            <p>{post.content}</p>
            {/* Render other post data as needed */}
            <img src={post.imageUrl} alt={post.title} />
          </li>
        ))}
      </ul>
    </div>
  );
}

export default App;
