import React from 'react'
import Homesection from '../Components/Homesection'
import Homesection2 from '../Components/Homesection2'
export default function Home(data) {
  if(data.data.length > 0){ 
    return (
      <>
      <Homesection/>
      <Homesection2 data = {data}/>
    </>
  )
}else{

}
}
