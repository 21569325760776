import React from 'react'
import Trendingcomntant from '../Components/Trendingcomntant'

export default function Trending() {
  // console.log(data.data);
  return (
    <>
      <Trendingcomntant />
    </>
  )


}
