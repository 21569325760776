import React from 'react'
import Articalsection from '../Components/Articalsection'
export default function Artical(data) {
//  console.log(data.data.data)
  return (
    <>
    <Articalsection data={data}/>
    </>
  )
}
