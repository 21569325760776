import React from 'react'
import Blogsection from '../Components/Blogsection'
export default function Blog(data) {

  if (data.data.length > 0) {
    return (
      <>
        <Blogsection data={data} />
      </>
    )
  } else {

  }
}
