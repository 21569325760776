import React, { useState, useEffect } from 'react';
import logo from '../News-_images/Logo.png';
import search from '../News-_images/search.png';
import cloud from '../News-_images/cloud.png';
import { Link } from 'react-router-dom';
import '../App.css';
import axios from 'axios';

export default function Header(data) {
  const [menuItems, setMenuItems] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://ludhiananow.com/wp-json/wp/v2/menu-items', {
          auth: {
            username: 'logicdigger',
            password: 'YcJe pQHx nrXc AQEB Gf0h H83X',
          },
       
        });
        console.log("fghjk", response);
        setMenuItems(response.data);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchData();
  }, []);
  const routes = [
    { path: '/', label: 'Home' },
    { path: '/blog', label: 'Blog' },
    { path: '/punjab', label: 'Punjab' },
    { path: '/trending', label: 'Trending' },
    { path: '/videos', label: 'Videos' },
    { path: '/sports', label: 'Sports' },
    { path: '/search', label: 'search' },
  ];

  console.log(data.data);
  return (
    <>
      <div className="container-fluid pe-0">
        <div className="d-flex">
          <div className="breaking-news d-flex justify-content-center align-items-center">
            <h6 className='digger-news-color m-0 footer-font-family fw-bolder'>Latest News</h6>
          </div>
          <div id="ticker">
            <ul>
              {Array.isArray(data.data) && data.data.length > 0 ? (
                data.data.map((post) => (
                  <li key={post.id}>
                    <Link to={`/${post.slug}`}>
                      {post.title.rendered}
                    </Link>
                  </li>
                ))
              ) : (
                <li>No posts found</li>
              )}
            </ul>

          </div>
        </div>
      </div>
      <nav className="navbar navbar-expand-lg navbar-light bg-light p-0 shadow header-main-navbar sticky-top">
        <div className="container header-all-contant digger-font-size digger-para-font" >
          <Link className="navbar-brand" to="/">
            <img src={logo} alt="logo" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse text-end flex-grow-0 Features-navbar-con news-navbar" id="navbarSupportedContent">
            <ul className="navbar-nav digger-font-family digger-font-size me-auto mb-2 mb-lg-0 fw-semibold main-header-links header-navbar-con colum text-center">
              {/* Render menu items */}
              {menuItems.map((menuItem, index) => (
                <li key={index} className="nav-item digger-font-weight col">
                  <Link className="nav-link py-0 px-3 all-navbar digger-news-color news-nav-pages" aria-current="page" to={routes[index].path}>
                    {menuItem.title.rendered}
                  </Link>
                </li>
              ))}
            </ul>
            <div className="navbar-search px-3 ">
              <Link to="/search">
                <img src={search} alt="search-icon" />
              </Link>
            </div>
            <div className="navbar-search ">
              <div className="digger-font-family cloud-search d-flex align-items-center justify-content-center gap-1">
                <img src={cloud} alt="cloud-icon" />
                <p className='m-0 pb-2'>28 C</p>
              </div>
              <div className="latest-date digger-font-family">
                <p>Wed. Dec 13th, 2023</p>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
