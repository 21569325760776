// import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom'
// const Trendingcomntant = () => {

//   const [posts, setPosts] = useState([]);
//   const [error, setError] = useState(null);
//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await fetch('https://ludhiananow.com/wp-json/wp/v2/posts');
//         if (!response.ok) {
//           throw new Error('Failed to fetch posts');
//         }
//         const data = await response.json();
//         if (!Array.isArray(data)) {
//           throw new Error('Invalid data format');
//         }
//         // Add a viewCount property to each post
//         const postsWithViews = await Promise.all(data.map(async (post) => {
//           const viewCountResponse = await fetch(`https://ludhiananow.com/wp-json/wp/v2/posts/${post.id}`);
//           const viewCountData = await viewCountResponse.json();
//           return { ...post, views: viewCountData.viewsCount };
//         }));
//         // Sort posts by views
//         const sortedPosts = postsWithViews.sort((a, b) => b.views - a.views);
//         setPosts(sortedPosts);
//       } catch (error) {
//         setError(error.message);
//       }
//     };

//     fetchData();
//   }, []);
//   // console.log("posts", posts);
//   return (
//     <div className="container my-5">  

//       <div className="row trending-contents ">
//         {posts.map(post => (
//           <div className="col-md-4" key={post.id}>
//             <div className="card border-0">
//               <Link to={`/${post.slug}`}>
//                 <img src={post.thumbnail_image_url} className="card-img-top" alt="..." />
//               </Link>
//               <div className="card-body p-0">
//                 <Link to={`/${post.slug}`}>
//                   <p>{post.views}</p>
//                   <h1 className='digger-font-family'>{post.title.rendered}</h1>
//                 </Link>
//                 <div dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }} />
//                 {/* <Link to={`/${post.slug}`}><button type="button" className="btn p-0 mb-5">
//                   Read More
//                 </button></Link> */}
//               </div>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>

//     // <div>
//     //   {error && <div>Error: {error}</div>}
//     //   <ul>
//     //     {posts.map(post => (
//     //       <li key={post.id}>
//     //         <h2>{post.title.rendered}</h2>
//     //         <p>{post.views}</p>
//     //         {/* Use dangerouslySetInnerHTML for HTML content */}
//     //         <div dangerouslySetInnerHTML={{ __html: post.content.rendered }}></div>
//     //       </li>
//     //     ))}
//     //   </ul>
//     // </div>
//   );
// };

// export default Trendingcomntant;




import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Loder from './Loder';
import Popular from './Popular';
import Latest from './Latest';
import Popular2 from './Popular2';

const Trendingcomntant = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://ludhiananow.com/wp-json/wp/v2/posts');
        if (!response.ok) {
          throw new Error('Failed to fetch posts');
        }
        const data = await response.json();
        if (!Array.isArray(data)) {
          throw new Error('Invalid data format');
        }
        // Add a viewCount property to each post
        const postsWithViews = await Promise.all(
          data.map(async (post) => {
            const viewCountResponse = await fetch(`https://ludhiananow.com/wp-json/wp/v2/posts/${post.id}`);
            const viewCountData = await viewCountResponse.json();
            return { ...post, views: viewCountData.viewsCount };
          })
        );
        // Sort posts by views
        const sortedPosts = postsWithViews.sort((a, b) => b.views - a.views);
        setPosts(sortedPosts);
        setLoading(false); // Set loading to false when data is fetched
      } catch (error) {
        setError(error.message);
        setLoading(false); // Set loading to false on error
      }
    };

    fetchData();
  }, []);

  return (
    <div className="container my-5">
      <div className="row">
        <div className="col-md-9">
          {loading ? (
            <div className="text-center">
              <Loder />
            </div>
          ) : (
            <div className="row trending-contents">
              {posts.map((post) => (
                <div className="col-md-4" key={post.id}>
                  <div className="card border-0">
                    <Link to={`/${post.slug}`}>
                      <img src={post.thumbnail_image_url} className="card-img-top" alt="..." />
                    </Link>
                    <div className="card-body p-0">
                      <Link to={`/${post.slug}`}>
                        <p>{post.views}</p>
                        <h1 className="digger-font-family">{post.title.rendered}</h1>
                      </Link>
                      <div dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }} />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
          {error && (
            <div className="text-center">
              <p>Error: {error}</p>
            </div>
          )}
        </div>
        <div className="col-md-3 py-4 popular-subscribe">
          <Latest />
          <Popular />
          <Popular2 />
        </div>
      </div>
    </div>
  );
};

export default Trendingcomntant;
