import React, { useState, useEffect } from 'react';
import Popular from './Popular';
import Popular2 from './Popular2';
import Latest from './Latest';
const SearchContent = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [posts, setPosts] = useState([]);
  const [error, setError] = useState('');
  const [searchExecuted, setSearchExecuted] = useState(false);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const urlSearchTerm = urlSearchParams.get('s');
    if (urlSearchTerm) {
      setSearchTerm(urlSearchTerm);
      fetchPosts(urlSearchTerm);
    }
  }, []);

  const fetchPosts = async (term) => {
    try {
      const response = await fetch(`https://ludhiananow.com/wp-json/wp/v2/posts?search=${term}`);
      if (!response.ok) {
        throw new Error('Failed to fetch posts');
      }
      const data = await response.json();

      setPosts(data);
      setError('');
      setSearchExecuted(true);
    } catch (error) {
      setPosts([]);
      setError('Error fetching posts. Please try again.');
      console.error('Error fetching posts:', error);
      setSearchExecuted(true);
    }
  };

  const handleSearchChange = (e) => {
    const newSearchTerm = e.target.value;
    setSearchTerm(newSearchTerm);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Fetch posts when the form is submitted
    fetchPosts(searchTerm);

    // Update the URL with the search term
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('s', searchTerm);
    const newUrl = `${window.location.pathname}?${searchParams}`;
    window.history.replaceState({ path: newUrl }, '', newUrl); // Use replaceState instead of pushState
  };

  return (
    <div className='container'>
      <div className="row">
        <div className="col-md-9">
          <div className="blog-heading d-flex digger-font-family my-4">
            <h3 className="fw-bold">LUDHIANA:<span className="digger-news-color">NOW</span></h3>
          </div>
          <form onSubmit={handleSubmit}>
            <div className='w-75 d-flex gap-5 m-auto pb-5'>
              <input
                type="search"
                className="form-control border-end-0 border rounded-pill"
                id="example-search-input"
                value={searchTerm}
                onChange={handleSearchChange} // Update search term on change
                required
              />
              <button
                type="submit"
                className="digger-bg-color text-light border-end-0 border rounded-pill btn "
              >
                Search
              </button>
            </div>
          </form>

          {error && <p>{error}</p>}
          {searchExecuted && posts.length === 0 && !error ? (
            <h1>NO POSTS FOUND!</h1>
          ) : (
            posts.map((post) => (
              <div key={post.id}>
                <img className='w-100 latest-img' src={post.thumbnail_image_url} alt="" />
                <h2>{post.title.rendered}</h2>
                <p dangerouslySetInnerHTML={{ __html: post.content.rendered }} />
                <hr />
              </div>
            ))
          )}
        </div>
        <div className="col-md-3 mt-5">
          <Latest />
          <Popular />
          <Popular2 />
        </div>
      </div>
    </div>
  );
};

export default SearchContent;
