import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom';

export default function Popular2() {
  const [data, setData] = useState([]);
  const apiUrl = 'https://ludhiananow.com/wp-json/wp/v2/posts?categories?';

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Axios.get(apiUrl);
        if (response && response.data) {
          setData(response.data);
        } else {
          console.error(`Failed to fetch data. Status code: ${response.status}`);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const limitedData = data.slice(0, 5);

  return (
    <>
      <div className="latest-part">
        <h4 className='digger-news-color digger-font-family'>TRENDING</h4>
      </div>

      {limitedData.map((post) => (
        <div className="latest-img col-md-12" key={post.id}>
          <div className="row mb-3">
            <div className="col-md-4 home-popular col-sm-6">
              <Link to={`/${post.slug}`}>
                <img className='w-100 latest-img' src={post.thumbnail_image_url} alt="" />
              </Link>
            </div>
            <div className="latest-discripition col-md-8 col-sm-6">
              <Link to={`/${post.slug}`}>
                <h6> {post.title.rendered}</h6>
              </Link>
              <div className="time d-flex align-items-center gap-2 flex-wrap">
                <p className="m-0">Ludhiana News</p>
                <p className="m-0">{new Intl.DateTimeFormat('en-US', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                }).format(new Date(post.date))}</p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}
